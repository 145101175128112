@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/articles.module.scss' as *;
@use '/styles/mixin/videoPlayicon.module.scss' as *;
.drive-news-single__side-bar {
  &__wrapper {
    @apply w-320px;
    @apply relative;

    @media (min-width: theme('screens.lg')) {
      width: 18.625rem;
      @apply mt-12;
    }
  }
  &__annex-cards {
    @apply mb-10;
  }
  &__ad-posR1 {
    @apply hidden;
    @screen lg {
      @apply block;
    }
  }
  &__ad-posR2-wrapper {
    @apply bg-white;
    @apply visible;
    @apply items-center;
    @apply z-50;
    @apply overflow-visible;
    @apply sticky;
    @apply top-0;
  }
  &__ad-posR2 {
    @apply w-full;
    @apply hidden;
    @screen md {
      @apply flex;
    }
  }
}
.drive-news-single {
  &__main-content {
    [class*='videoPlayer_drive-review__video-player__play-btn-wrapper'] {
      :global(.drive-icon) {
        @screen xs {
          @include video-icon-dimension('medium');
        }
        @screen lg {
          @include video-icon-dimension('large');
        }
      }
    }
    :global(.wp-block-drive-blocks-standfirst) {
      @apply mb-0;
    }
    :global(.wp-block-drive-blocks-standfirst > p) {
      @apply mb-2;
    }
  }
  &__linked-guides {
    @apply text-white;
    @apply flex;
    @apply items-center;
    @apply justify-start;
    @apply px-1.5;
    @apply py-2;
    @apply max-w-[126px];
    @apply border-[1px];
    @apply border-transparent;
    @apply border-solid;
    @apply rounded-[4px];
    @include font-caption('desktop', 'bold');
    @screen md {
      @apply px-2;
      @apply max-w-[140px];
    }
    &:hover {
      @apply border-[1px];
      @apply border-solid;
      @apply text-white;
    }
    &--w-full {
      @apply flex;
      @apply rounded-[8px];
      @apply h-12;
      @apply justify-center;
      @screen md {
        @apply justify-between;
        @apply rounded-none;
        @apply mb-4;
      }
      [class^='linked-guide_d-linked-guide__hub-link__left'] {
        @apply gap-3;
      }
    }
  }
  &__name-wrapper {
    @apply flex-row-reverse;
    @apply justify-between;
    @screen md {
      @apply flex;
    }
  }
  &__name-container {
    @apply pl-2;
    @apply hidden;
    @screen md {
      @apply block;
    }
  }
  &__name {
    @apply inline-block;
    @apply whitespace-nowrap;
    @apply bg-black;
    @apply uppercase;
    @apply text-white;
    @apply py-1.5;
    @apply px-3;
    @apply mb-6;
    @include font-label;
  }
  &__title {
    @apply mb-3;
    @apply mt-0;
    @include font-heading('mobile', 'h2');
    @screen md {
      @include font-heading('desktop', 'h2');
      @apply max-w-[605px];
    }
  }
  &__excerpt {
    @apply mb-4;
    @include font-standfirst('mobile');
  }

  &__hr {
    @apply hidden;
    @apply border;
    @apply border-gray-300;
    @apply mt-5;
    @screen md {
      @apply block;
    }
  }
  &__comment-ad-wrapper {
    @apply items-center;
  }
  &__comment-ad-wrapper-div {
    @apply w-full;
    @apply hidden;
    @apply top-0;
    @apply z-50;
    @apply sticky;
    @apply overflow-visible;
    @screen md {
      @apply flex;
    }
  }

  &__breadcrumbs {
    @apply mb-[1.75rem];
    @screen md {
      @apply mb-0;
    }
  }
  &__desktop-ad {
    @apply hidden #{!important}; //important is used to override style from ad component
    @apply justify-center;
    @apply w-full;
    @screen lg {
      @apply flex #{!important};
    }
  }

  &__you-may-also-like {
    @include rightEdgedYMALSlides;
    @apply ml-0;
    @screen lg {
      @apply ml-5;
    }
    @screen xl {
      @apply ml-0;
    }
  }
}
