@use '/styles/mixin/fonts.module.scss' as *;

.d-curated-carlist {
  &__wrapper {
    @apply w-full;
    @apply mt-6;
    @apply pb-[8rem];

    @media screen and (min-width: 500px) and (max-width: 599px) {
      @apply pb-[10rem];
    }

    @media screen and (min-width: 600px) and (max-width: 767px) {
      @apply pb-[12rem];
    }

    @screen md {
      @apply pb-[8rem];
    }

    @screen lg {
      @apply border-none;
      @apply pb-[5.5rem];
      @apply my-12;
    }
  }

  &__tabs-wrapper {
    @apply flex;
    @apply justify-center;
    @apply mb-6;

    ul {
      @apply flex;
    }

    &--title {
      @apply px-6;
      @apply cursor-pointer;
      @apply text-center;

      @screen md {
        @include font-subtext('mobile', 'large');
      }

      @screen lmd {
        @include font-subtext('desktop', 'large');
      }

      @screen xl {
        @include font-heading('mobile', 'h3');
      }

      &:global(.active) {
        @apply text-blue-dark;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        border-right: 2px solid black;
      }
    }

    :global(.carlist-react-select) {
      @apply w-full;
    }

    :global(.carlist-react-select__control) {
      @apply h-12;
      @apply border-black;
      @apply text-black;
      @apply shadow-none;
      @include font-subtext('mobile', 'large');
      &:hover {
        @apply border-black;
        @apply text-black;
      }
    }
    :global(.carlist-react-select__menu) {
      @apply z-10;
    }
    :global(.carlist-react-select__placeholder) {
      color: inherit;
      @apply m-0;
      @include font-subtext('mobile', 'large');
    }
    :global(.carlist-react-select__dropdown-indicator) {
      color: inherit;
    }
    :global(.carlist-react-select__option) {
      @include font-subtext('mobile', 'large');
      color: inherit;
    }
    :global(.carlist-react-select__indicators) {
      color: inherit;
      :global(.drive-icon-ChevronUp) {
        @apply hidden;
      }
      :global(.drive-icon-ChevronDown) {
        @apply flex;
      }
    }
    &__menu {
      @apply z-20;
    }
    :global(.carlist-react-select__control--menu-is-open) {
      @apply border-black;
      @apply text-black;
      @apply shadow-none;
      :global(.m-react-select__placeholder) {
        color: inherit;
      }

      :global(.carlist-react-select__indicators) {
        color: inherit;
        :global(.drive-icon-ChevronUp) {
          @apply flex;
        }
        :global(.drive-icon-ChevronDown) {
          @apply hidden;
        }
      }
    }

    &__chevron-up {
      @apply h-3;
      @apply mr-5;
      color: inherit;
    }

    &__chevron-down {
      @apply h-3;
      @apply mr-5;
      color: inherit;
    }
  }

  &__models-wrapper {
    @apply flex;
    @apply justify-center;
  }

  &__desk-model-image {
    @apply cursor-pointer;
    @apply text-center;
    @include font-subtext('desktop', 'medium');
    @apply mx-2;
    @apply text-black;

    @screen xl {
      @apply mx-[6px];
    }

    img {
      @apply transition-all;
    }

    @screen lg {
      &:hover {
        @apply text-blue-dark;

        img {
          transform: scale3d(1.1, 1.1, 1);
        }
      }
    }
  }

  &__model-image {
    @apply cursor-pointer;
    @apply text-center;
    @apply text-black;

    h3 {
      @include font-subtext('desktop', 'medium');
    }

    img {
      @apply w-full;
      @apply h-auto;
      @apply transition-all;
    }

    @screen lg {
      &:hover {
        img {
          transform: scale3d(1.1, 1.1, 1);
        }
      }
    }

    &:hover {
      @apply text-black;

      @screen lg {
        @apply text-blue-dark;
      }
    }
  }

  &__glide-wrapper {
    @apply relative;
    @apply mr-[-20px];

    @screen md {
      @apply mr-[-56px];
    }

    @screen lg {
      @apply mr-0;
    }

    [class*='glideSlider_glide__slides--not-mounted'] {
      @apply flex-row;

      [class*='curated-car-list_d-curated-carlist__model-image'] {
        // img and img containers on first few seconds of load happens here
        @media screen and (max-width: 767px) {
          // we dont have to worry too much about mobile for CLS, as it wouldnt show on viewport
          min-width: calc(100% / 2.5);
        }
        @media screen and (max-width: 1023px) {
          // these are not perfect calculations, as smaller screens would have different sizes for each image
          min-width: 149.22px;
          &:nth-child(1),
          &:nth-child(8) {
            min-width: 143.22px;
          }
          img {
            min-width: 137.22px;
          }
        }
        // using min-width would prioritize this stylings on desktop
        @media screen and (min-width: 1024px) {
          // the calculation is based on final calculation of img size, plus consideration of margins on each side
          width: 156.5px;

          &:nth-child(1),
          &:nth-child(8) {
            // first and last images doesnt have margins so they have smaller sizes
            width: 150.5px;
          }

          img {
            width: 144.5px;
          }
        }
      }
    }
  }
}
