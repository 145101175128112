@use '/styles/mixin/fonts.module.scss' as *;
.drive-article-hero-carousel {
  &__image {
    @apply cursor-pointer;
    @apply object-cover;
    @apply h-full;
    @apply w-full;
  }
  &__caption {
    @apply text-black;
    @apply font-primary;
    @apply font-medium;
  }
  &__container {
    @apply w-full;
    @apply relative;
    aspect-ratio: 16/9;

    @screen lg {
      @apply h-[440px];
    }

    :global([class~='lightbox__open-btn']) {
      @apply right-4;
    }
  }
  &__lightbox-btn-position {
    @apply text-black;
    @apply bg-white/50;
    @include font-caption('mobile', 'bold');
    @screen md {
      @include font-button;
    }
  }
}
