@use '/styles/mixin/fonts.module.scss' as *;
.drive-inline-carousel {
  &__image {
    @apply cursor-pointer;
    @apply object-cover;
    @apply h-full;
    @apply w-full;
  }
  &__caption {
    @apply text-black;
    @apply font-primary;
    @apply font-normal;
    @apply text-12px;
    @apply pt-2;
    @apply min-h-44px;
    @apply px-10px;
    @apply whitespace-normal;

    @screen sm {
      @apply text-13px;
      @apply font-medium;
    }
    @screen md {
      @apply px-3px;
    }
    &__block-option {
      @include font-heading('desktop', 'h3');
      @apply py-3.5;
      @screen md {
        @include font-heading('desktop', 'h2');
        padding: 1.188rem 0.188rem;
      }
      @apply bg-black;
      @apply text-center;
      @apply text-white;
    }
  }
  &__container {
    @apply h-full;
    @apply relative;
  }
  &__lightbox-btn-position {
    @apply text-black;
    @apply bg-white/50;
  }
  &__wrapper {
    @apply h-full;
  }
}
