@use '/styles/mixin/fonts.module.scss' as *;
.drive-post__more-links {
  &__desktop {
    @apply hidden;
    @apply my-10;
    @apply flex-wrap;
    @screen md {
      @apply flex;
    }
  }
  &__mobile {
    @apply my-10;
    @apply flex;
    @apply flex-wrap;
    @screen md {
      @apply hidden;
    }
  }
}

.drive-post__more-link {
  &__wrapper {
    @apply flex;
    @apply items-start;
    @apply w-full;
    @apply pb-3;
    @screen md {
      @apply pb-2;
      @apply w-1/2;
    }

    [class^='link_drive-link__external__text'] {
      @apply inline;
      @include font-subtext('mobile', 'large');
      @screen md {
        @include font-subtext('desktop', 'medium');
      }
    }
  }
  &__heading {
    @apply mr-2;
    @include font-subtext('mobile', 'large');
    @screen md {
      @include font-subtext('desktop', 'medium');
    }
  }

  &__heading-link {
    @apply items-center;
    [class^='link_drive-link__external__text'] {
      @apply inline;
      @include font-subtext('mobile', 'large');
      @screen md {
        @include font-subtext('desktop', 'medium');
      }
    }
  }
}
