@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/articles.module.scss' as *;
@use '/styles/mixin/videoPlayicon.module.scss' as *;

.drive-car-advice__side-bar-top {
  &__wrapper {
    @apply w-320px;
    @apply relative;

    @media (min-width: theme('screens.lg')) {
      width: 18.625rem;
      margin-top: 3rem;
    }
  }
  &__annex-cards {
    @apply mb-10;
  }
  &__ad-desktop {
    @apply hidden;
    @screen lg {
      @apply block;
    }
  }
  &__ad-sticky {
    @apply bg-white;
    @apply visible;
    @apply items-center;
    @apply z-50;
    @apply overflow-visible;
    @apply sticky;
    @apply top-0;
  }
  &__ad-sticky-wrapper {
    @apply w-full;
    @apply hidden;
    @screen md {
      @apply flex;
    }
  }
}

.drive-car-advice__single {
  &__main-content {
    [class*='videoPlayer_drive-review__video-player__play-btn-wrapper'] {
      :global(.drive-icon) {
        @screen xs {
          @include video-icon-dimension('medium');
        }
        @screen lg {
          @include video-icon-dimension('large');
        }
      }
    }
    :global(.wp-block-drive-blocks-standfirst) {
      @apply mb-0;
    }
    :global(.wp-block-drive-blocks-standfirst > p) {
      @apply mb-2;
    }
  }
  &__news-topic {
    @apply flex-row-reverse;
    @apply justify-between;
    @screen md {
      @apply flex;
    }
  }

  &__name {
    @screen md {
      @apply pl-2;
    }
  }
  &__name-wrapper {
    @apply inline-block;
    @apply whitespace-nowrap;
    @apply bg-black;
    @apply uppercase;
    @apply text-11px;
    @apply text-white;
    @apply py-1.5;
    @apply px-3;
    @apply mb-6;
  }
  &__linked-guides-top--desktop {
    @apply text-white;
    @apply h-7;
    @apply flex;
    @apply items-center;
    @apply justify-start;
    @apply px-1.5;
    @apply py-2;
    @apply max-w-[126px];
    @apply border-[1px];
    @apply border-transparent;
    @apply border-solid;
    @apply rounded-[4px];
    @screen md {
      @apply px-2;
      @apply h-8;
      @apply max-w-[140px];
    }
    @include font-caption('desktop', 'bold');
    &:hover {
      @apply border-[1px];
      @apply border-solid;
      @apply text-white;
    }
  }

  &__title {
    @apply mb-3;
    @apply mt-0;
    @include font-heading('mobile', 'h2');
    @screen md {
      @include font-heading('desktop', 'h2');
    }
  }
  &__excerpt {
    @apply mb-2;
    @apply text-18px;
    @apply leading-8;
    @screen md {
      @apply text-20px;
    }
  }
  &__separator {
    @apply hidden;
    @apply border;
    @apply border-gray-300;
    @apply mt-5;
    @screen md {
      @apply block;
    }
  }
  &__comment-ad-wrapper {
    @apply items-center;
  }
  &__comment-ad-wrapper-div {
    @apply w-full;
    @apply hidden;
    @apply top-0;
    @apply z-50;
    @apply sticky;
    @apply overflow-visible;
    @screen md {
      @apply flex;
    }
  }
  &__you-may-also-like {
    @include rightEdgedYMALSlides;
    @apply ml-0;
    @screen lg {
      @apply ml-5;
    }
    @screen xl {
      @apply ml-0;
    }
  }
}
.linked-guides-full-width {
  @include font-button('desktop');
  @apply flex;
  @apply rounded-[8px];
  @apply h-12;
  @apply justify-center;
  @screen md {
    @include font-subtext('desktop', 'medium');
    @apply justify-between;
    @apply rounded-none;
    @apply mb-2 #{!important};
  }
  [class^='linked-guide_d-linked-guide__hub-link__left'] {
    @apply gap-3;
  }
}
