@use '/styles/mixin/fonts.module.scss' as *;
.drive-car-specs {
  &__container {
    @apply w-full;
    @apply flex;
    @apply flex-col;
    @apply items-start;
    @apply pt-4;
    @apply px-2;
  }

  &__list-container {
    @apply w-full;
    @apply flex;
    @apply flex-wrap;
    @apply -mx-1;
    @apply overflow-hidden;
    @screen md {
      @apply pb-6;
    }
  }
  &__list-item {
    @apply my-1;
    @apply px-1;
    @apply w-1/2;
    @apply overflow-hidden;
    @screen md {
      @apply w-1/4;
    }
    :global(img.drive-icon-EnginePowerIcon) {
      @apply mt-1;
    }
    :global(img.drive-icon-TransmissionIcon) {
      @apply mt-1;
    }
  }
  &__item-name {
    @apply m-0;
    @apply ml-4;
    @apply items-center;
    @apply flex;
    @include font-text('mobile', 'medium');
    @screen sm {
      @apply block;
    }
    @screen md {
      @include font-text('desktop', 'medium');
    }
  }
  &__linked-guides {
    @include font-button('desktop');
    @apply flex;
    @apply rounded-[8px];
    @apply h-12;
    @screen md {
      @include font-subtext('desktop', 'medium');
      @apply justify-between;
      @apply rounded-none;
    }
  }
  &__link-container {
    @apply w-full;
    @apply flex;
    @apply flex-wrap;
    @apply flex-col;
    @apply mt-1;
    @screen md {
      @apply items-center;
      @apply flex-row;
    }
    @media (max-width: 768px) {
      :global(.dropdown.similar-cars .content .item) {
        @apply p-1.5;
        @apply px-4;
      }
    }
  }

  &__link {
    @apply min-w-56;
    @apply flex;
    @apply justify-end;
    @apply border-b;
    @apply pb-4;
    @apply my-4;
    @screen md {
      @apply justify-start;
      @apply border-0;
      @apply pb-0;
    }

    [class^='link_drive-link__external__text'] {
      @include font-subtext('mobile', 'medium');
      @screen md {
        @include font-subtext('desktop', 'medium');
      }
    }
  }
}
