@use '/styles/mixin/fonts.module.scss' as *;

.d-linked-guide {
  &__hub-link {
    @apply items-center;
    @apply px-[7px];
    @apply py-[3px];
    @apply text-white;
    @apply border-[1px];
    @apply border-transparent;
    @apply border-solid;
    @apply mb-1;
    @apply inline-block;
    @apply rounded-md;
    @screen md {
      @apply px-[9px];
      @apply py-[3px];
    }
    &:hover {
      @apply border-[1px];
      @apply border-solid;
      @apply text-white;
    }

    &:global(.fullWidth) {
      @apply w-full;
      @apply flex;
      @apply py-[11px];
      @apply px-4;
      @apply rounded-md;
      @screen lg {
        @apply rounded-[0];
      }
    }

    &:global(.electric) {
      @apply bg-teal-dark;

      &:hover {
        @apply bg-teal-normal;
        @apply border-teal-dark;
      }
    }

    &:global(.family) {
      @apply bg-blue-normal;

      &:hover {
        @apply border-blue-dark;
      }
    }

    &:global(.finance) {
      @apply bg-blue-darkest;

      &:hover {
        border: 1px solid rgba(9, 38, 112, 1);
      }
    }

    &__left {
      @apply flex;
      @apply items-center;
      @apply gap-1.5;
      @apply flex-none;
      span {
        @include font-caption('mobile', 'bold');
        @screen md {
          @include font-caption('desktop', 'bold');
        }
      }

      &__wrapper {
        @apply max-w-6; // restrict to 24px since image is loaded 48px
        > img {
          @apply w-5;
          @screen md {
            @apply w-full;
          }
        }
      }
    }
  }

  &__link-icon {
    @apply hidden;

    @screen md {
      @apply block;
    }
  }
}
