@use '/styles/mixin/fonts.module.scss' as *;
.drive-post__meta {
  &__wrapper {
    @apply items-center;
    @apply justify-between;
    @apply flex-col;
    @apply mt-4;
    @screen md {
      @apply flex-row;
      @apply flex;
      @apply mt-4;
    }
  }
  &__authors {
    @apply relative;
    @apply flex;
    @apply mb-8;
    @screen md {
      @apply items-center;
      @apply mb-0;
    }
  }
  &__avatar-desktop {
    @apply inline-block;
    @apply border-white;
    @apply mt-4;
    @apply h-10;
    @apply w-10;
    @screen md {
      @apply mt-0;
      @apply h-12;
      @apply w-12;
    }
    &-one {
      @apply -mr-5;
    }
  }
  &__co-authors {
    @apply pl-2;
    @screen md {
      @apply flex;
    }
  }
  &__co-authors-wrapper {
    @apply pt-3;
    @screen md {
      @apply pt-0;
    }
  }
  &__co-author-one {
    @include font-subtext('mobile', 'medium');
  }
  &__co-author-two {
    @include font-subtext('mobile', 'medium');
    @apply mr-5;
  }
  &__timestamp {
    @apply text-gray-400;
    @include font-subtext('mobile', 'medium');
    @screen md {
      @include font-caption('desktop');
    }
  }
  &__counts {
    @apply flex;
    @apply items-center;
    @apply justify-between;
    @apply ml-1;
  }
  &__count {
    @apply text-black;
    @apply flex;
    @apply flex-wrap;
    @apply justify-center;
    @apply mx-1;
    // override the default style from article card comment count
    :global [class^='comments-count_drive-article-card__comment__count'] {
      @apply min-w-[0.4875rem];
      @include font-caption('mobile', 'bold');
      @screen md {
        @include font-caption('desktop', 'bold');
      }
    }
    &:first-child {
      @apply ml-0;
    }
    &:last-child {
      @apply mr-0;
    }

    @include font-subtext('mobile', 'medium');
    @screen md {
      @include font-caption('desktop');
    }

    :global [class^='articleCard_drive-article-card__share__wrapper'] {
      @apply ml-0;
    }

    &__icon {
      @apply min-h-[1.5rem];
      @apply text-black;

      @screen md {
        @include font-caption('desktop');
        @apply flex;
      }
    }
  }

  &__co-author-link {
    @include font-subtext('mobile', 'medium');
    @apply text-black;
    @screen md {
      @include font-caption('desktop');
    }
  }
  &__date {
    @apply pl-2;
  }
}
