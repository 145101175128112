@use '/styles/mixin/fonts.module.scss' as *;

.drive-home {
  &__wrapper {
    @apply flex;
    @apply flex-col;
    @apply items-center;
    [class^='carousel_drive-home-carousel__container__featured-articles'],
    [class^='car-for-sales home_drive-home__cfs'] {
      @apply max-w-[20rem];
      @apply ml-auto;
      @apply mr-auto;
      @screen md {
        @apply max-w-none;
      }
    }
    [class^='compareThumbnails_drive-home-compare-thumbnails__compare-specs'] {
      @apply max-w-[20rem];
      @apply pl-2;
      @screen md {
        @apply max-w-none;
      }
    }
    [class^='compareThumbnails_drive-home-compare-thumbnails__compare-specs-title'] {
      @apply pl-0;
    }
    [class^='latestArticles_drive-home-latest-articles'] {
      @apply max-w-[20rem];
      @apply mr-auto;
      @apply ml-auto;
      @screen md {
        @apply max-w-none;
      }
    }
    [class^='latestArticles_drive-home-latest-articles__title'] {
      margin-left: unset;
    }
    [class^='home_drive-home__cfs__container'] {
      @apply mt-[-1.25rem];
    }

    //compound selector for higher specificity
    [class^='articleAnnexCards_drive-annex-cards__home-page-container'][class*='advice-articles'] {
      @apply w-full-screen;
      @apply -ml-5;
      @media screen and (min-width: 480px) {
        @apply ml-0;
        @apply w-full;
      }

      h2 {
        @apply pl-5;

        @media screen and (min-width: 480px) {
          @apply pl-0;
        }
      }
    }

    //compound selector for higher specificity
    [class^='articleAnnexCards_drive-annex-cards__home-page-container'][class*='col-3'] {
      @apply w-full-screen;
      @apply pt-8;
      @media screen and (min-width: 480px) {
        @apply w-full;
      }
      @screen lg {
        @apply pt-[44px];
      }
      [class^='articleAnnexCards_drive-annex-cards__heading-wrapper'] {
        @apply ml-0;
        @apply pl-5;
        @media screen and (min-width: 480px) {
          @apply pl-0;
        }
      }
      [class^='articleAnnexCards_drive-annex-cards__bottom-link'] {
        @include font-subtext('mobile', 'medium');
        @apply h-6;
        @apply flex;
        @apply items-center;
        //shift bottom link closer to card bottom border
        //figma space - compoent initial space = shift space
        @apply mt-0; // 16 - 16 = 0
        @screen md {
          @apply mt-[-10px]; // 8 - 18 = -10
        }
        @screen lmd {
          @apply mt-[-14px]; // 8 - 22 = -14
        }
        @screen xl {
          @apply mt-[-4px]; // 16 - 22 = -4
        }
      }
    }
  }

  &__advice-articles__wrapper {
    @screen md {
      @apply min-w-[323px];
    }
    @screen lmd {
      @apply min-w-[400px];
    }
    @screen xl {
      @apply mr-[35px];
    }
  }
  //heading links ,compound seletor for higher specificity
  &__article-slider-wrapper > &__header-link,
  &__wrapper [class^='articleAnnexCards_drive-annex-cards__heading-wrapper'] > a {
    @apply text-blue-dark;
    @apply self-baseline;
    @apply mb-4;
    @screen md {
      @apply ml-0;
      @apply mb-[22px];
    }
  }

  &__section-heading {
    @apply m-0;
    @include font-heading('mobile', 'h4');
    @screen xl {
      @include font-heading('desktop', 'h4');
    }
    &:hover {
      @apply underline;
    }
  }
  &__section-heading-ca {
    @apply text-black;
    @apply m-0;
    @include font-heading('mobile', 'h4');
    @screen md {
      @include font-heading('desktop', 'h4');
    }
    &:hover {
      @apply text-blue-dark;
    }
  }
  &__container {
    @apply min-h-screen;
    @apply z-4;
    @screen md {
      @apply p-0;
    }
  }
  &__layout {
    @apply bg-white;
    @screen md {
      @apply flex-row;
    }
  }
  &__cfs {
    @apply w-full;
  }
  &__cfs__title {
    @include font-heading('mobile', 'h3');
    @apply font-semibold;
    @apply mb-6;
    @screen md {
      @include font-heading('desktop', 'h2');
    }
  }
  &__cfs__container {
    @apply w-full;
    @apply flex;
    @apply flex-wrap;
    @apply items-start;
    @apply justify-center;
    @screen lg {
      @apply justify-between;
    }
  }
  &__cfs__section {
    @apply flex;
    @apply flex-col;
    @apply w-full;
    @apply justify-center;
    @screen md {
      @apply flex-row;
      @apply w-auto;
    }
  }
  &__cfs__section-used {
    @apply mt-10;
    @screen md {
      @apply mt-0;
      @apply ml-4;
    }
  }
  &__cfs__card {
    @apply max-w-300;
    @apply mt-11;
    @screen md {
      @apply mx-10;
    }
    @screen lg {
      @apply mx-0;
    }
  }

  &__latest-owner-reviews-slider {
    @apply mt-0;
  }
  &__latest-review-slider,
  &__latest-caradvice-slider {
    @apply mt-8;
    @screen md {
      @apply mt-6;
    }
    @screen xl {
      @apply mt-8;
    }
  }

  &__compare-cards-wrapper {
    @apply mt-8;
    @screen xl {
      @apply mt-12;
    }
    @apply mb-8;
  }

  &__three-col-layout {
    @apply w-full;
    @apply flex;
    @apply flex-col;
    @apply mt-8;
    @apply mb-8;
    @screen md {
      @apply mb-6;
      @apply flex-row;
    }
    @screen lg {
      @apply mb-[44px];
    }
    [class^='articleAnnexCards_drive-annex-cards__bottom-link'] {
      @apply mr-5;
      @include font-subtext('mobile', 'medium');
      @screen md {
        @apply mr-0;
      }
    }
  }

  &__ad {
    &-pos2 {
      @apply flex;
      @apply justify-center;
      &-wrapper {
        @apply w-full;
        @apply -my-1;
        @media (min-width: 600px) {
          @apply -mt-1;
          @apply -mb-3;
        }
        @screen lg {
          @apply mt-[-2px];
        }
        @screen xl {
          @apply mt-[10px];
          @apply mb-0;
        }
      }
    }

    &-pos3 {
      @apply flex;
      @apply justify-center;
      &-wrapper {
        @apply -my-2;
        @media (min-width: 600px) {
          @apply my-0;
          [class*='adSpacing_drive-ad-spacing'] {
            @apply my-[-22px];
          }
        }
        @screen lg {
          [class*='adSpacing_drive-ad-spacing'] {
            @apply my-0;
          }
        }
      }
    }

    &-pos4 {
      @apply flex;
      @apply justify-center;
      &-wrapper {
        @apply -mb-4;
        @apply -mt-15;
        @apply w-full;
        @screen md {
          @apply mt-[-68px];
          @apply mb-[-26px];
        }
        @screen lg {
          @apply mt-[-42px];
          @apply mb-[-4px];
        }
      }
    }

    &-pos5 {
      @apply flex;
      @apply justify-center;
      &-wrapper {
        @apply pt-8;
        @apply pb-7;
        @apply w-full;
        @screen md {
          @apply py-[32px];
        }
        @screen lg {
          @apply py-[52px];
        }
      }
    }

    &-posR1 {
      @apply hidden;
      @screen xl {
        @apply flex;
      }
    }
  }
}
