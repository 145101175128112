.d-container {
  @apply flex;
  @apply flex-col;
  @apply gap-[10px];
  @screen md {
    @apply flex-row;
  }
  &__ad-wrapper {
    @apply w-full;
    &--ad-has-data {
      @apply h-12;
    }
  }
}
